/* eslint-disable react/destructuring-assignment */
import React, { Suspense } from 'react';

import { observer, inject } from 'mobx-react';
import {
  Layout, Menu, Button, Col, Popover, Avatar, List, Tooltip
} from 'antd';
import {
  MenuOutlined,
  SettingOutlined,
  UserAddOutlined,
  ScheduleOutlined,
  SolutionOutlined,
  FileSyncOutlined,
  LogoutOutlined,
  UserOutlined,
  LineChartOutlined,
  VideoCameraOutlined,
  GlobalOutlined
} from '@ant-design/icons';

import {
  Switch, Route, Link, withRouter,
} from 'react-router-dom';
import debounce from 'lodash/debounce';
import Loading from '../basic/Loading';
// import getAgentInstance from '../../helpers/superagent';
import routes from './dashboardRoutes';
import largeLogo from '../../assets/images/Main on black@4x.png';
import './dashboard.css';

const {
  Header, Content, Footer, Sider,
} = Layout;

@inject('userStore', 'tokenStore', 'sectionsStore')
@observer
class Dashboard extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({
      collapsed: false,
      // imageId: '',
      windowsWidth: 0,
      UIFlags: {},
      isSecretary: true,
      defualtMenu: 'visitlist',
    });
    this.state = this.initialState();

    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
      }));
    };
    this.logout = () => {
      const { userStore, tokenStore, sectionsStore } = this.props;
      Promise.all([
        userStore.clear(),
        tokenStore.clear(),
        sectionsStore.clear(),
      ]).then(() => { window.location.href= "/" });
    };

    this.updateDimensions = debounce(() => {
      this.setState({ windowsWidth: window.innerWidth });
      const { windowsWidth } = this.state;
      if (windowsWidth < 992) {
        this.setState({ collapsed: true });
      } else {
        this.setState({ collapsed: false });
      }
    }, 300);
  }

  componentDidMount() {
    this.setState({
      UIFlags: this.props.userStore.value.role_data
        ? (this.props.userStore.value.role_data.UIFlags || {}) : {},
      isSecretary: this.props.userStore.value.is_secretary,
    });
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const {
      collapsed, UIFlags, isSecretary,
    } = this.state;
    const { userStore } = this.props;
    const { location } = this.props;
    const App = process.env.REACT_APP_CURRENT_APP;
    return (
      <>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            theme="dark"
            style={{
              height: '100vh',
              left: 0,
              position: 'fixed',
              overflow: 'auto',
              background: '#2d3546',
            }}
            width={collapsed ? 0 : 240}
          >
            <div
              className="logo"
              style={{
                padding: 21,
                textAlign: 'center',
                background: '#1f2532',
              }}
            >
              <img alt="app-logo" style={{ width: 140 }} src={largeLogo} />
            </div>
            <Menu
              theme="dark"
              selectedKeys={[location.pathname]}
              mode="inline"
              style={{ background: '#2d3646', marginTop: 35 }}
            >

              {
                UIFlags.dashboard || !isSecretary
                  ? (
                    <Menu.Item key="/">
                      <LineChartOutlined style={{ fontSize: 17 }} />
                      <span>Dashboard</span>
                      <Link to="/" />
                    </Menu.Item>
                  ) : null
              }
              { App == "PMS" ?(
                UIFlags.visitlist || !isSecretary
                  ? (
                    <Menu.Item key="/visitList">
                      <FileSyncOutlined style={{ fontSize: 17 }} />
                      <span>Visit List</span>
                      <Link to="/visitList" />
                    </Menu.Item>
                  ) : null 
              ) : null
              }
              { App == "Telehealth" ? (
              UIFlags.visitlist || !isSecretary ? (
                <Menu.Item key="/telemedicine-visit">
                  <VideoCameraOutlined />
                  Telemedicine Visit
                  <Link to="/telemedicine-visit" />
                </Menu.Item>
                ) : null
                ) : null
              }
              {
                App == "Telehealth" ?
                  (UIFlags.visitlist || !isSecretary ? (
                
                <Menu.Item key="/international-telemedicine-visit">
                  <Tooltip title="International Telemedicine Visit" position="top" trigger="hover">
                      <GlobalOutlined />
                      International Telemedicine Visit
                      <Link to="/international-telemedicine-visit" />
                    </Tooltip>
                </Menu.Item>
               
                  ) : null)
                  : null
              } 
              {  App == "PMS" ? (
                UIFlags.patient || !isSecretary
                  ? (
                    <Menu.Item key="/patient">
                      <SolutionOutlined style={{ fontSize: 17 }} />
                      <span>Patient</span>
                      <Link to="/patient" />
                    </Menu.Item>
                  ) : null 
                  ) : null
              }

              {/* <Menu.Item key="/booking">
                <BookOutlined />
                <span>Booking</span>
                <Link to="/booking" />
              </Menu.Item> */}

              {
                App == "PMS" ? (
                UIFlags.schedule || !isSecretary
                  ? (
                    <Menu.Item key="/schedule">
                      <ScheduleOutlined style={{ fontSize: 17 }} />
                      <span>Schedule</span>
                      <Link to="/schedule" />
                    </Menu.Item>

                  ) : null
                  ) : null
              }
              {
                App == "Telehealth" ? (
                UIFlags.schedule || !isSecretary
                  ? (
                    <Menu.Item key="/telemedicine-schedule">
                      <ScheduleOutlined style={{ fontSize: 17 }} />
                      <span>Telemedicine Schedule</span>
                      <Link to="/telemedicine-schedule" />
                    </Menu.Item>

                    ) : null
                ) : null
              }
              {
                App == "PMS" ? (
                UIFlags.secretary || !isSecretary
                  ? (
                    <Menu.Item key="/clinic-users">
                      <UserAddOutlined style={{ fontSize: 17 }} />
                      <span>Clinic Users</span>
                      <Link to="/clinic-users" />
                    </Menu.Item>
                    ) : null
                    ) : null
              }

              {/* <Menu.Item key="/disease">
                <DiffOutlined />
                <span>Disease</span>
                <Link to="/disease" />
              </Menu.Item> */}

              {/* <Menu.Item key="/symptom">
                <SubnodeOutlined />
                <span>Symptoms</span>
                <Link to="/symptom" />
              </Menu.Item> */}

              {
                UIFlags.myprofile || !isSecretary
                  ? (
                    <Menu.Item key="/myprofile">
                      <SettingOutlined />
                      <span>My Profile</span>
                      <Link to="/myprofile" />
                    </Menu.Item>
                  ) : null
              }

            </Menu>
          </Sider>
          <Layout
            className="site-layout"
            style={{
              transition: 'all 0.2s',
              marginLeft: collapsed ? 0 : 240,
            }}
          >
            <Header
              className="site-layout-background"
              style={{ padding: 0, display: 'inherit' }}
            >
              <Col span={12} style={{ textAlign: 'start', paddingLeft: 10 }}>
                <Button
                  type="link"
                  icon={
                    <MenuOutlined style={{ fontSize: 15, color: '#1f2532' }} />
                  }
                  onClick={this.toggleSidebar}
                />
              </Col>
              <Col span={12} style={{ textAlign: 'end', paddingRight: 15 }}>

                <Popover
                  content={(
                    <List>
                      {
                        UIFlags.myprofile || !isSecretary
                          ? (
                            <List.Item>
                              <Link to="/myprofile">
                                <Button type="link" icon={<UserOutlined />}>
                                  My Profile
                                </Button>
                              </Link>
                            </List.Item>
                          )
                          : null
                      }

                      <List.Item>
                        <Button
                          onClick={this.logout}
                          icon={<LogoutOutlined />}
                          danger
                          type="link"
                          block
                        >
                          Logout
                        </Button>
                      </List.Item>
                    </List>
                    )}
                  trigger="hover"
                  arrowPointAtCenter
                  placement="bottomLeft"
                >
                  <Avatar
                    size="large"
                    src={
                    !isSecretary ? `${process.env.REACT_APP_API_LINK}/files/${userStore.value.image_id}` : undefined
                      }
                    icon={isSecretary ? <UserOutlined /> : undefined}
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.2) -1px 0px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                  />
                </Popover>

              </Col>
            </Header>
            <Content style={{ margin: '16px 16px', marginBottom: 0 }}>
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 500 }}
              >
                <Suspense fallback={<Loading />}>
                  <Switch>
                    {routes.map((route) => (
                      <Route
                        exact={route.exact}
                        key={route.key}
                        path={`${route.path}`}
                      >
                        <route.component />
                      </Route>
                    ))}
                  </Switch>
                </Suspense>
              </div>
            </Content>
          </Layout>
        </Layout>
      </>
    );
  }
}

export default withRouter((props) => <Dashboard {...props} />);
