import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Select,
  AutoComplete,
  notification,
  Modal,
} from "antd";
import { observer, inject } from "mobx-react";
import getAgentInstance from "../../helpers/superagent";
import { auth } from "../../helpers/firebase";
import { RecaptchaVerifier, isSignInWithEmailLink, signInWithPhoneNumber, sendSignInLinkToEmail, signInWithEmailLink } from "firebase/auth";

const superagent = getAgentInstance();
const emailPattern = new RegExp(
  "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
);
const App = process.env.REACT_APP_CURRENT_APP;
@inject("userStore", "tokenStore")
@observer
class DoctorLogin extends Component {
  constructor() {
    super();
    this.initialState = () => ({
      loading: false,
      phoneNo: undefined,
      isModalOpen: false,
    });

    this.state = this.initialState();
    this.goBack = () => {
      this.setState({
        phoneNo: undefined,
      });
    };

    this.sendCode = (values) => {
      const zis = this;
      zis.setState({ loading: true });
      const phonePattern = /^0?[1-9][1-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/i;
      var generatedPhoneNo = values.phone_no_or_email.replace(/\s/g, "");
      if (/^\d+$/.test(generatedPhoneNo)) {
        if (phonePattern.test(generatedPhoneNo)) {
          generatedPhoneNo = values.country_code + generatedPhoneNo;
          window.grecaptcha.getResponse(window.recaptchaWidgetId);
          const appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, `+${generatedPhoneNo}`, appVerifier)
            .then((confirmationResult) => {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
              zis.setState({ phoneNo: generatedPhoneNo });
              // console.log('ok');
              zis.setState({ loading: false });
            })
            .catch((error) => {
              notification.error({
                message: "Sorry..",
                description: error.code,
                placement: "bottomRight",
              });
              window.recaptchaVerifier.render().then((widgetId) => {
                window.grecaptcha.reset(widgetId);
              });
              zis.setState({ loading: false });
            });
        } else {
          notification.error({
            message: "Error",
            description: "invalid Phone number",
            placement: "bottomRight",
          });
          zis.setState({ loading: false });
        }
      }
      
      if (App == "Telehealth" && !/^\d+$/.test(generatedPhoneNo)) {
        const email = values.phone_no_or_email.trim();
        if (emailPattern.test(email)) {
          var actionCodeSettings = {
            url: window.location.href,
            handleCodeInApp: true,
          };
          superagent
            .post("/doctor/auth/email-link/setp1")
            .send({
              email: email,
            })
            .end((err, res) => {
              if (!err && res && res.statusCode == 204) {
                  sendSignInLinkToEmail(auth, email, actionCodeSettings)
                  .then(() => {
                    window.localStorage.setItem("emailForSignIn", email);
                    notification.success({
                      message: "Email link sent",
                      description:
                        "An Email link has been sent to your email, please open the email link to login",
                      placement: "bottomRight",
                      duration: 0,
                    });
                  })
                  .catch((error) => {
                    notification.error({
                      message: "Sorry..",
                      description: error.code,
                      placement: "bottomRight",
                    });
                    window.recaptchaVerifier.render().then((widgetId) => {
                      window.grecaptcha.reset(widgetId);
                    });
                    zis.setState({ loading: false });
                  });
              } else if (err || (!res && res.statusCode == 404)) {
                notification.error({
                  message: "Not Found",
                  description: "Account does not exist",
                  placement: "bottomRight",
                });
              }
              zis.setState({ loading: false });
            });
        } else {
          window.recaptchaVerifier.render().then((widgetId) => {
            window.grecaptcha.reset(widgetId);
          });
          zis.setState({ loading: false });
          notification.error({
            message: "Error",
            description: "invalid email",
            placement: "bottomRight",
          });
        }
      }
    };
    this.doLogin = (values) => {
      const { code } = values;
      const { phoneNo } = this.state;
      window.confirmationResult
        .confirm(code)
        .then(async (result) => {
          const { user } = result;
          const idToken = await user.getIdToken();
          await this.getAuthData(phoneNo, idToken);
        })
        .catch((error) => {
          notification.error({
            message: "Sorry..",
            description: error.code,
            placement: "bottomRight",
          });
        });
    };
    this.doEmailLogin = (value) => {
      this.setState({ loading: true });
      const email = value.email ?? value;
      if (emailPattern.test(email)) {
          signInWithEmailLink(auth, email, window.location.href)
          .then(async (result) => {
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            const { userStore, tokenStore } = this.props;
            const idToken = await result.user.getIdToken();
            if (idToken != null) {
              this.setState({ loading: true });
              superagent
                .post("/doctor/auth/email-link/setp2")
                .send({
                  id_token: idToken,
                  uid: result.user.uid,
                  email: email,
                })
                .set("Content-Type", "application/json")
                .type("json")
                .end((err, res) => {
                  this.setState({ loading: false });
                  if (!err) {
                    const { body } = res;
                    userStore.set(body.data);
                    tokenStore.set(body.token);
                  }
                });
            }
          })
          .catch((error) => {
            notification.error({
              message: "Sorry..",
              description: error.code,
              placement: "bottomRight",
            });
            this.setState({ loading: false });
          });
      } else {
        notification.error({
          message: "Error",
          description: "invalid email",
          placement: "bottomRight",
        });
        this.setState({ loading: false });
      }
    };
    this.getAuthData = (phoneNo = "", idToken = "") => {
      const { userStore, tokenStore } = this.props;
      this.setState({ loading: true });
      superagent
        .post("/doctor/auth/phone")
        .send({
          id_token: idToken,
          phone_no: phoneNo,
        })
        .set("Content-Type", "application/json")
        .type("json")
        .end((err, res) => {
          this.setState({ loading: false });
          if (!err) {
            const { body } = res;
            userStore.set(body.data);
            tokenStore.set(body.token);
          }
        });
    };
  }

  componentDidMount() {
    if (window) {
      const recaptchaOptions = {
        siteKey: "6LelgcMpAAAAAIfgcBiYuNpeoBUm3AxNgjPG21fA",
        size: "invisible",
        callback() {
        },
      };
      window.recaptchaVerifier = new RecaptchaVerifier( auth,
        "recaptcha",
        recaptchaOptions,
      );
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    }
    if (App == "Telehealth" && isSignInWithEmailLink(auth, window.location.href)) {
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        this.setState({
          isModalOpen: true,
        });
      } else {
        this.doEmailLogin(email);
      } 
    }
  }

  render() {
    const { loading, phoneNo, isModalOpen } = this.state;
    const { loginHandler } = this.props;
    return (
      <>
        <Col span={24} offset={1}>
          <Row>
            <Col span={24}>
              <Card bordered={false}>
                {phoneNo === undefined ? (
                  <Form
                    name="basic"
                    initialValues={{}}
                    layout="vertical"
                    onFinish={this.sendCode}
                  >
                    <Form.Item
                      label={
                        "Doctor phone number" +
                        (App == "Telehealth" ? " or email" : "")
                      }
                      name="phone_no_or_email"
                      rules={[
                        {
                          required: true,
                          message: "Phone number is required",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={"Enter doctor's phone number" + (App == "Telehealth" ? " or email" : "")}
                        addonBefore={
                          <Form.Item
                            name="country_code"
                            noStyle
                            initialValue="964"
                          >
                            <Select style={{ width: 70 }}>
                              <Select.Option value="964">964</Select.Option>
                            </Select>
                          </Form.Item>
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <div id="recaptcha" />
                    </Form.Item>
                    <Form.Item
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Row>
                        <Col span={6} style={{ textAlign: "left" }}>
                          <Button
                            type="link"
                            onClick={loginHandler}
                            style={{ padding: 0 }}
                          >
                            Clinic User?
                          </Button>
                        </Col>
                        <Col span={18}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                          >
                            Send
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form>
                ) : null}

                {phoneNo !== undefined ? (
                  <Form
                    name="basic"
                    initialValues={{}}
                    layout="vertical"
                    onFinish={this.doLogin}
                  >
                    <Form.Item
                      label="Verify"
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Verification code is required",
                        },
                      ]}
                    >
                      <AutoComplete />
                    </Form.Item>
                    <Form.Item>
                      <Row>
                        <Col span={13}>
                          <Button
                            type="link"
                            style={{ padding: 0, color: "gray" }}
                            onClick={this.goBack}
                          >
                            Back
                          </Button>
                        </Col>
                        <Col span={11} style={{ textAlign: "right" }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form>
                ) : null}
              </Card>
            </Col>
          </Row>
        </Col>
        {App == "Telehealth" ? (
          <Modal
            closable={true}
            maskClosable={true}
            onCancel={() => this.setState({ isModalOpen: false })}
            visible={isModalOpen}
            title="Enter Email to log in"
            footer={false}
            centered={true}
          >
            <Form
              name="emailLinkLogin"
              initialValues={{}}
              layout="vertical"
              onFinish={this.doEmailLogin}
            >
              <Form.Item
                label="Doctor email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  {
                    type: "email",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter doctor's email"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                style={{
                  textAlign: "right",
                }}
              >
                <Row>
                  <Col span={24}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Login
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Modal>
        ) : null}
      </>
    );
  }
}

export default DoctorLogin;
