/* eslint-disable max-classes-per-file */
import { action, observable, makeObservable, computed, reaction } from 'mobx';
import { persist, create } from 'mobx-persist';

class Sections {
  @persist('list') @observable value = [];
  constructor() {
    makeObservable(this);
  }
  @action clear() {
    this.value = [];
  }
  @action set(value) {
    this.value = value;
  }
}
class Token {
  @persist @observable value = '';
  constructor() {
    makeObservable(this);
  }
  @action clear() {
    this.value = '';
  }
  @action set(value) {
    this.value = value;
  }
}
class User {
  @persist('object') @observable value = {};
  constructor() {
    makeObservable(this);
  }
  @action clear() {
    this.value = {};
  }
  @action set(value) {
    this.value = value;
  }
}
class Form {
  @observable formRef = null;
  intialFormValue = observable.box(null, { deep: false });

  constructor() {
    makeObservable(this);
  }

  @action
  setFormRef(ref) {
    this.formRef = ref;
  }
  @action
  setIntialFormValue(value) {
    this.intialFormValue = value;
  }
  @computed
  get formIsTouched() {
    return !_.isEqual(
      this.formRef.current.getFieldsValue(),
      this.intialFormValue
    );
  }
  @action
  reset() {
    this.formRef = null;
    this.intialFormValue = observable.box(null, { deep: false });
  }
}
class Tab {
  @observable activeKey = '0';

  constructor() {
    makeObservable(this);
  }
  @action
  next() {
    let num = parseInt(this.activeKey);
    ++num;
    this.activeKey = num.toString();
  }
  @action
  previous() {
    let num = parseInt(this.activeKey);
    --num;
    this.activeKey = num.toString();
  }
  @action
  changeTab(newTabKey) {
    this.activeKey = newTabKey;
  }
}

class VisitData {
  @observable reload = false;
  @observable fetchFunctions = [];
  @observable functionsToRun = 0;
  constructor() {
    makeObservable(this);
    reaction(
      () =>
        this.fetchFunctions.length == this.functionsToRun &&
        this.reload == true,
      () => this.refetch()
    );
  }
  @action
  setReload(reload) {
    this.reload = reload;
  }
  @action
  refetch() {
    this.reload = false;
    for (let index = 0; index < this.fetchFunctions.length; index++) {
      this.fetchFunctions[index].call();
    }
    this.fetchFunctions = [];
  }
  @action
  addFetchFunction(func) {
    this.fetchFunctions.push(func);
  }
  @action
  incrementFunctionsToRun(increment) {
    this.functionsToRun += increment;
  }
  @action
  reset() {
    this.reload = false;
    this.fetchFunctions = [];
    this.functionsToRun = 0;
  }
}

// const store = (window.store = new Store());
const userStore = new User();
const tokenStore = new Token();
const sectionsStore = new Sections();
const hydrate = create({
  storage: localStorage,
  jsonify: true,
});
hydrate('app-user', userStore).then(() => {});
hydrate('app-login-token', tokenStore).then(() => {});
hydrate('app-sections', sectionsStore).then(() => {});

export default {
  userStore,
  tokenStore,
  sectionsStore,
  formStore: new Form(),
  telemedicineVisitTab: new Tab(),
  visitData: new VisitData(),
};
